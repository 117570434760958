
import Vue from 'vue'
import { capitalizeFirstLetter } from '@/utils/strings'
import { emailAllowedSymbolsRegExp, discordAllowedSymbolsRegExp } from '@/constants/baseConstatnts'
import SERVICES, { getServiceConfigProperty } from '@/constants/services'
import { VueRecaptcha } from 'vue-recaptcha'
import phoneInput from '@/components/pages/register/phoneInput/index.vue'
import { formatDiscordNickname } from '@/utils/formatters'

declare const window: Window & {
  dataLayer: Record<string, unknown>[];
  _tmr: Record<string, unknown>[];
}

let vm: any = null
let refCode: string

export default Vue.extend({
  name: 'registerPage',
  data: () => ({
    partnerName: '',
    form: {
      valid: false,
      fields: {
        captcha: {
          value: '',
          errors: []
        },
        last_name: {
          value: '',
          rules: [
            (v: string) => Boolean(v.trim().length) || 'registration.errors.required',
            (v: string) => v.trim().length > 1 || 'registration.errors.lastNameShort',
            (v: string) => {
              const re = /^[A-Za-zА-Яа-яЁё\s]+$/i
              return re.test(v.trim()) || 'registration.errors.lastNameInvalid'
            }
          ],
          errors: []
        },
        first_name: {
          value: '',
          rules: [
            (v: string) => Boolean(v.trim().length) || 'registration.errors.required',
            (v: string) => v.trim().length > 1 || 'registration.errors.firstNameShort',
            (v: string) => {
              const re = /^[A-Za-zА-Яа-яЁё\s]+$/i
              return re.test(v.trim()) || 'registration.errors.firstNameInvalid'
            }
          ],
          errors: []
        },
        patronymic: {
          value: '',
          rules: [
            (v: string) => {
              if (v.length) {
                const re = /^[A-Za-zА-Яа-яЁё\s]{2,}$/i
                return re.test(v.trim()) || 'registration.errors.patronymicInvalid'
              }
              return true
            }
          ],
          errors: []
        },
        phone: { //  example
          value: '', //  9188888888
          valueWithDial: '', // +79188888888
          valid: false,
          errors: []
        },
        email: {
          value: '',
          rules: [
            (v: string) => Boolean(v.trim().length) || 'registration.errors.required',
            (v: string) => emailAllowedSymbolsRegExp.test(v.trim()) || 'registration.errors.emailIncorrect'
          ],
          errors: []
        },
        discord: {
          value: '',
          rules: [
            (v: string) => {
              if (!getServiceConfigProperty('isDiscordRequired')) return true
              if (v.trim().length > 0) return true
              return 'registration.errors.required'
            },
            (v: string) => (discordAllowedSymbolsRegExp.test(v.trim()) || v.trim() === '') || 'registration.errors.discordInvalid'
          ],
          errors: []
        },
        agreement: {
          value: false,
          rules: [
            (v: boolean) => v || 'registration.errors.agreement'
          ],
          errors: []
        }
      },
      usedErrors: {
        phone: false,
        email: false
      },
      responseWarnings: [],
      isLoading: false
    }
  }),
  methods: {
    onVerify (response: string) {
      this.form.fields.captcha.value = response
      this.form.fields.captcha.errors = []
    },
    onExpired () {
      this.form.fields.captcha.value = ''
      this.form.fields.captcha.errors = []
    },
    handleCaptchaError (error: any) {
      this.form.fields.captcha.value = ''
      this.form.fields.captcha.errors = [error]
    },
    resetRecaptcha () {
      this.$refs.recaptcha.reset()
    },
    async getPartner () {
      const vm = this as any

      if (vm.$route.params.partner) {
        const response = await vm.$services.authorization.signup.getPartner(vm.$route.params.partner)
        if (response.success) {
          vm.partnerName = response.data.name
          refCode = vm.$route.params.partner
        } else {
          response.data.errors.forEach((message: string) => {
            vm.form.responseWarnings.push(capitalizeFirstLetter(message))
          })
        }
      }
      if (vm.$route.query.ref) {
        const response = await vm.$services.authorization.signup.getPartner(vm.$route.query.ref)
        if (response.success) {
          vm.partnerName = response.data.name
          refCode = response.data.token
        } else {
          response.data.errors.forEach((message: string) => {
            vm.form.responseWarnings.push(capitalizeFirstLetter(message))
          })
        }
      }
    },
    handleClickHandleRules (): void {
      window.open(`https://${window.location.host}/id/legal-information`, '_blank')
    },
    toggleCheckboxValue (event: any) {
      const vm = this as any
      if (event.target.getAttribute('type') === 'checkbox') {
        vm.form.fields.agreement.value = !vm.form.fields.agreement.value
      }
    },
    changePasswordInputType (type: string): void {
      const vm = this as any
      vm.form.fields[type].type = vm.form.fields[type].type === 'password' ? 'text' : 'password'
    },
    checkPasswordsEqual (): void {
      const vm = this as any
      const p1 = vm.form.fields.password1.value
      const p2 = vm.form.fields.password2.value
      if (p1.length && p2.length) {
        if (p1 !== p2) {
          if (!vm.form.fields.password2.errorMessages.length) {
            vm.form.fields.password2.errorMessages.push('This value should be equal value of field above')
          }
        } else {
          vm.form.fields.password2.errorMessages.shift()
        }
      }
    },
    async validate (): Promise<void> {
      const vm = this as any
      this.form.isLoading = true

      const request: any = {}
      for (const [key, value] of Object.entries(this.form.fields)) {
        const item: any = value
        if (key === 'phone') {
          request[key] = item.valueWithDial
        } else if (key === 'discord') {
          request[key] = formatDiscordNickname(item.value)
        } else request[key] = item.value
      }
      if (refCode) {
        request.ref = refCode
      }
      this.form.usedErrors = {
        phone: false,
        email: false
      }
      const response: any = await vm.$services.authorization.signup.doSignUp(request)
      if (response.success) {
        /* window.ym(89268028, 'reachGoal', 'Sign Up')
        window.ym(93312770, 'reachGoal', 'Sign Up') */
        window.ym(95601145, 'reachGoal', 'registration')
        window.dataLayer.push({ event: 'registration' })
        window._tmr.push({ type: 'reachGoal', id: 3467065, goal: 'registration' })
        vm.$store.commit('setMessageInstanceState', {
          opened: true,
          title: vm.$t('passwordRepair.messages.registerMailSended'),
          description: vm.$t('passwordRepair.messages.registerMailSubtitle', { email: vm.form.fields.email.value }),
          actionBtn: {
            text: 'passwordRepair.messages.resend',
            action: async () => {
              const response: any = await vm.$services.authorization.signup.resendEmail({ email: this.form.fields.email.value })
              if (!response.success) {
                vm.$router.replace({ name: 'ErrorPage' })
              }
            },
            retry: true
          }
        })
      } else {
        for (const [key, untypedErrorArray] of Object.entries(response.data)) {
          const errorArray = untypedErrorArray as string[]
          if (key === 'phone' || key === 'email') {
            const usedIndex = errorArray.findIndex(err => err === 'used')
            if (usedIndex !== -1) {
              this.form.usedErrors[key] = true
              errorArray.splice(usedIndex, 1)
            }
          }
          if (key in this.form.fields) this.form.fields[key].errors = errorArray
        }
        this.resetRecaptcha()
      }

      this.form.isLoading = false
    },
    clearFieldCustomErrors (field: string) {
      this.form.fields[field].errors = []
    }
  },
  computed: {
    captchaKey () {
      return SERVICES.CAPTCHA_KEY
    },
    formValid () {
      return this.form.valid && this.form.fields.phone.valid
    }
  },
  created (): void {
    vm = this as any
    vm.getPartner()
  },
  components: {
    VueRecaptcha,
    phoneInput
  }
})
